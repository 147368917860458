<template>
  <div class="mdEditor-page">
    <a-spin :spinning="loading" wrapperClassName="md-loading">
      <div class="loading-box">
        <div id="mdContent" />
        <saveDialog
          v-if="visibleSaveDialogFlag"
          @saveToDoc="saveToDoc"
          @closevisibleSaveDialog="closevisibleSaveDialog"
          :visibleSaveDialogFlag="visibleSaveDialogFlag"
        />

        <a-modal
          title="关闭文档"
          :footer="null"
          :visible="closeMdEditorFlag"
          :closable="false"
        >
          <div class="closeMd-content">
            未保存的编辑，关闭将会丢失内容, 要继续吗？
          </div>
          <div class="closeMd-footer">
            <a-button
              type="primary"
              style="margin-right: 15px"
              @click="saveAndOut"
              >保存并退出</a-button
            >
            <a-button style="margin-right: 15px" @click="justOut"
              >仅退出</a-button
            >
            <a-button
              style="margin-right: 15px"
              @click="closeMdEditorFlag = false"
              >取消</a-button
            >
          </div>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import saveDialog from "./components/saveDialog.vue";
import Vditor from "vditor";
import "vditor/dist/index.css";
import SparkMD5 from "spark-md5";
import { start, stop, record } from '@/utils/danaAi/audioToText.js'
import { mapGetters } from 'vuex';

function createId() {
  let now = Date.now();
  var d = new Date().getTime();
  var uuid = "xxyyxxy".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 10) % 10 | 0;
    d = Math.floor(d / 10);
    return r.toString();
  });
  return now + uuid;
}
export default {
  name: "HomeView",
  data() {
    return {
      closeMdEditorFlag: false,
      loading: false,
      type: "",
      userFileId: "",
      parentId: "",
      teamId: null,
      visibleSaveDialogFlag: false, // 是否显示保存输入文件名弹框
      mdContentVal: "", // md编辑区内容
      vditor: null,
      isTape: false, // 是否开启录音
    };
  },
  components: {
    saveDialog,
  },

  async mounted() {
    this.type = await new URLSearchParams(window.location.search).get("type");
    this.userFileId = await new URLSearchParams(window.location.search).get(
      "userFileId"
    );
    this.teamId = await new URLSearchParams(window.location.search).get(
      "teamId"
    );
    this.parentId = await new URLSearchParams(window.location.search).get(
      "parentId"
    );
    const mdContentDom = document.getElementById("mdContent");
    let that = this
    this.vditor = new Vditor(mdContentDom, {
      height: "100%",
      width: "100%",
      mode: "wysiwyg",
      preview: {
        theme: "dark",
        hljs: {
          // 设置代码块主题
          style: "dracula",
        },
        actions: [],
      },
      upload: {
        accept: "video/*,image/*,.zip,.rar,.7z",
        handler: async(files) => {
          let res
          for (let file of files) {
            let chunkInfo = await this.computeMD5(file)
            // 上传前像服务端进行确认
            let params = {
              moduleType: "doc",
              companyId: "person",
              parentId: "",
              filename: file.name,
              relativePath: file.name,
              totalSize: file.size,
              identifier: chunkInfo.md5
            }
            let filePreUploadRes = await this.$apis.filePreUpload(params)
            if (filePreUploadRes.code === "200") {
              // 已存在，无需上传；不存在，则调用上传接口
              if (filePreUploadRes.data.skipUpload) {
                if (filePreUploadRes.data.status === "UPLOADED") {
                } else {
                  this.$set(file, "parentId", filePreUploadRes.data.parentId);
                }
                res = filePreUploadRes
              } else {
                // 文件不存在，则上传文件
                let start = 0
                // 大文件切片上传
                for (let i = 0; i < chunkInfo.chunkNum; i++) {
                  const fileChunk = file.slice(start, start + chunkInfo.chunkSize)
                  const formData = new FormData()
                  formData.append('chunkNumber', i + 1) // 切片编号
                  formData.append('chunkSize', chunkInfo.chunkSize) // 切片大小
                  formData.append('currentChunkSize', fileChunk.size) // 当前切片大小
                  formData.append('totalSize', chunkInfo.totalSize) // 总大小
                  formData.append('identifier', chunkInfo.md5)
                  formData.append('filename', file.name)
                  formData.append('relativePath', file.name)
                  formData.append('totalChunks', chunkInfo.chunkNum) // 切片总数
                  formData.append('parentId', '')
                  formData.append('fileName', file.name)
                  formData.append('moduleType', 'doc')
                  formData.append('companyId', 'person')
                  formData.append('historyId', filePreUploadRes.data.historyId)
                  formData.append('testChunks', true)
                  formData.append('file', fileChunk)
                  start = start + chunkInfo.chunkSize
                  res = await this.$apis.fileUpload(formData)
                }
              }
              if (res.data.resourceUrl) {
                // 渲染上传的内容
                let _dom;
                if (file.type.startsWith('image')) {
                  if (file.name === 'image.png') {
                    let imgUrl = res.data?.resourceUrl?.split("url=")[1];
                    _dom = `![${file.name}](${imgUrl})`
                  } else {
                    _dom = `<a href="${res.data?.resourceUrl} ">${file.name}</a>`
                  }
                // } else if (file.type.startsWith('video')) {
                //   _dom = `<audio controls="controls" src="${res?.data?.resourceUrl?.split("url=")[1]}"></audio>`
                //   _dom = `<video controls="controls"><source src="${res.data.resourceUrl}"></source></video>`
                } else {
                  _dom = `<a href="${res.data.resourceUrl} ">${file.name}</a>`
                }
                that.vditor.insertValue(_dom)
              }
            }
          }
          if (res.data.resourceUrl) {
            return "上传成功"
          }
          return "上传失败"
        },
      },
      toolbarConfig: {
        pin: true,
      },

      toolbar: [
        // "emoji",
        "headings",
        "bold",
        "italic",
        "strike",
        "|",
        "line",
        "quote",
        "list",
        "ordered-list",
        "check",
        "outdent",
        "indent",
        "code",
        "inline-code",
        // "insert-after",
        // "insert-before",
        "undo",
        "redo",
        {
          name: "upload",
          tip: "上传图片或文件",
        },
        // "record",
        "link",
        "table",
        "edit-mode",
        "both",
        // "preview",
        // "fullscreen",
        // "outline",
        // "code-theme",
        "export",
        // "devtools",
        // "br",
        {
          name: 'aiToText',
          tipPosition: 'n',
          tip: 'AI速记',
          icon: '<svg><use xlink:href="#vditor-icon-record"></use></svg>',
          click: () => {
            if (!this.isTape) {
              // 开始录音转换
              this.$message.success("录音转换开始")
              this.recorderToTextStart()
            } else {
              // 结束录音转换
              this.$message.success("录音转换结束")
              this.recorderToTextStop()
            }
          }
        },
        {
          name: 'save',
          tipPosition: 'n',
          tip: '保存',
          icon: `<img style="width: 16px" src=${require('@/assets/svg/md_editor_save.svg')}>`,
          click: () => that.openSaveDialog()
        }, // 保存
        {
          name: 'out',
          tipPosition: 'n',
          tip: '退出',
          icon: `<img style="width: 16px" src=${require('@/assets/svg/close.svg')}>`,
          click: () => that.getOut()
        }, // 退出
      ],
      cache: {
        enable: false,
      },
      after: () => {
        // 这里给markdown编辑器设置文章内容
        // this.articleEditor.setValue(wikiContent);
        if (this.type == "read") {
          // return
          this.readMd();
        }
      },
    });
  },
  computed: {
    ...mapGetters([
      'getAiSearchConnState',
      'getAiSearchRecText'
    ])
  },
  watch: {
    getAiSearchRecText: {
      handler(val) {
        if (val) {
          this.vditor?.insertValue(val.replace(/\d{1,2}.\d{1,2}:|\n/g, "").toString())
        }
      },
      immediate: true
    },
    getAiSearchConnState: {
      handler(val) {
        if (val === 0) {
          if (this.isTape) {
            record(() => {
              this.isTape = true
            }, (err) => {
              stop(true)
              this.isTape = false
              this.$message.error(err?.message || err)
            })
          }
        } else {
          if (val === 2) {
            // 连接错误
            this.$message.error('服务器连接失败')
            this.isTape = false
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    // 录音转换打开
    recorderToTextStart() {
      const res = start(true)
      if (res === 1) {
        this.isTape = true
        this.$message.info('正在连接服务器, 请稍候')
      }
    },
    recorderToTextStop() {
      if (this.isTape) {
        this.isTape = false
        stop()
      }
    },
    /**
   * 计算md5，实现断点续传及秒传
   * @param {object} file 文件信息
   */
    computeMD5(file) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        let blobSlice =
          File?.prototype?.slice ||
          File?.prototype?.mozSlice ||
          File?.prototype?.webkitSlice;
        let currentChunk = 0;
        const chunkSize = 1024 * 1024;
        let chunks = Math.ceil(file.size / chunkSize);
        let spark = new SparkMD5.ArrayBuffer();
        // 文件状态设为"计算MD5", 并绑定set get方法
        this.$set(file, "statusStr", "计算MD5");
        loadNext();
        fileReader.onload = async (e) => {
          spark.append(e.target.result);
          if (currentChunk < chunks) {
            currentChunk++;
            loadNext();
            // 实时展示MD5的计算进度
            file.statusStr = `校验MD5 ${((currentChunk / chunks) * 100).toFixed(
              0
            )}%`;
          } else {
            let md5 = spark.end();
            resolve({
              totalSize: file.size,
              chunkSize,
              chunkNum: chunks,
              md5
            })
          }
        };
        fileReader.onerror = () => {
          this.$message.error(`文件${file.name}读取出错，请检查该文件`);
          file.cancel();
        };
        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize >= file.size ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
      })
    },
    saveAndOut() {
      this.closeMdEditorFlag = false;
      this.openSaveDialog();
    },
    getOut() {
      this.mdContentVal = this.getEditorContent();
      console.log("测试", this.mdContentVal);
      if (!this.mdContentVal.trim().length) {
        this.getOutToTargetRoter();
      } else {
        this.closeMdEditorFlag = true;
      }
    },
    justOut() {
      this.getOutToTargetRoter();
    },
    // 回退云文档逻辑
    getOutToTargetRoter() {
      if (this.teamId && this.teamId.length) {
        this.$router.push({
          name: "VIEW_COMPANYTEAM_DOCUMENT",
          query: {
            id: this.parentId,
            teamId: this.teamId,
          },
        });
      } else {
        this.$router.push({
          name: "VIEW_MY_DOCUMENT",
          query: {
            id: this.parentId,
          },
        });
      }
    },
    closevisibleSaveDialog() {
      this.visibleSaveDialogFlag = false;
    },

    async readMd() {
      try {
        this.loading = true;
        let params = {
          userFileId: this.userFileId,
        };
        const res = await this.$apis.readMdHttp(params);
        console.log("res", res);
        this.vditor.setValue(res?.data);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
      }
    },
    // 获取编辑器内容
    getEditorContent() {
      return this.vditor.getValue();
    },
    async openSaveDialog() {
      this.mdContentVal = this.getEditorContent();
      console.log("测试", this.mdContentVal);
      if (!this.mdContentVal.trim().length) {
        this.$message.error("请先输入内容");
        return;
      } else {
        // 这里也区分是读取md文件重新编写的，还是创建文档的，创建的才需要弹框输入文件名保存
        // 读取的情况
        if (this.type == "read") {
          let params = {
            content: this.getEditorContent(),
            userFileId: this.userFileId,
          };

          try {
            this.loading = true;
            // alert('更新文档开发中')
            const res = await this.$apis.updateMdFile(params);
            console.log("res", res);
            this.$message.success("更新成功");
            setTimeout(() => {
              this.getOutToTargetRoter();
            }, 1500);
          } catch (error) {
            console.log("error", error);
          } finally {
            this.loading = false;
          }
        }
        // 创建新文档的情况
        else {
          this.visibleSaveDialogFlag = true;
        }
      }
    },
    async saveToDoc(val) {
      console.log("父组件接受到保存参数了", val);
      let params = {
        ...val,
        source: "CREATE",
        content: this.getEditorContent(),
        moduleType: this.teamId ? "team" : "doc",
        parentId: this.parentId,
        teamId: this.teamId,
      };
      this.visibleSaveDialogFlag = false;
      try {
        this.loading = true;
        const res = await this.$apis.saveCreateAiFile(params);
        console.log("res", res);
        this.$message.success("保存成功");
        setTimeout(() => {
          this.getOutToTargetRoter();
        }, 1500);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mdEditor-page {
  width: 100%;
  height: 100%;
  padding: 30px;
  // 设置重置有序列表和无序列表样式
  /deep/ ul li {
    list-style-type: disc;
    ul li {
      list-style-type: circle;
      ul li {
        list-style-type: square;
      }
    }
  }
  /deep/ ol li {
    list-style-type: decimal;
  }

  .loading-box {
    width: 100%;
    height: 100%;
  }
  #mdContent {
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="less">
.md-loading {
  height: 100%;
  .ant-spin-container {
    height: 100% !important;
  }
}
.closeMd-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
}
.closeMd-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
