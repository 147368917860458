/* eslint-disable no-console */
import store from '@/store'
import { WebSocketConnectMethod } from './wsconnecter'
// @ts-expect-error no-err
const wsconnecter = new WebSocketConnectMethod({ msgHandle: getJsonMessage, stateHandle: getConnState })
let isReal = false
let isRec = false
// 录音; 定义录音对象,wav格式
const rec = Recorder({
  type: 'pcm',
  bitRate: 16,
  sampleRate: 16000,
  onProcess: recProcess,
})

let sampleBuf = new Int16Array()

let recText = '' // for online rec asr result
let offlineText = ''
let connState = null
let disabled = false

function handleWithTimestamp(tmptext, tmptime) {
  console.log('handleWithTimestamp====', tmptext)
  if (tmptime == null || tmptime === undefined || tmptext.length <= 0)
    return tmptext

  tmptext = tmptext.replace(/。/g, ',') // in case there are a lot of "。"
  const words = tmptext.split(',')
  const jsontime = JSON.parse(tmptime) // JSON.parse(tmptime.replace(/\]\]\[\[/g, "],[")); // in case there are a lot segments by VAD
  let char_index = 0
  let text_withtime = ''
  for (let i = 0; i < words.length; i++) {
    if (words[i] === undefined || words[i].length <= 0)
      continue
    text_withtime = `${text_withtime + jsontime[char_index][0] / 1000}:${words[i]}\n`
    char_index = char_index + words[i].length
  }
  return text_withtime
}
// 语音识别结果; 对jsonMsg数据解析,将识别结果附加到编辑框中
function getJsonMessage(jsonMsg) {
  const rectxt = `${JSON.parse(jsonMsg.data).text}`
  const asrmodel = JSON.parse(jsonMsg.data).mode
  const timestamp = JSON.parse(jsonMsg.data).timestamp
  if (asrmodel === '2pass-offline' || asrmodel === 'offline') {
    if (isReal) {
      offlineText = handleWithTimestamp(rectxt, timestamp)
    } else {
      offlineText = offlineText + handleWithTimestamp(rectxt, timestamp)
    }
    recText = offlineText
  }
  else {
    if (!isReal) {
      recText = recText + rectxt // .replace(/ +/g,"");
    }
  }
  store.commit('SET_SEARCH_RECTEXT', recText)
  // console.log(`offlineText: ${asrmodel},${offlineText}`)
  console.log(`Text: ${recText}`)
}

// 连接状态响应
function getConnState(state) {
  connState = state
  store.commit('SET_SEARCH_CONNSTATE', connState)
  if (state === 0) { // on open
    console.log('连接成功')
    disabled = false
  }
  else if (state === 1) {
    // stop()
  }
  else if (state === 2) {
    disabled = false
    stop(true)
  }
  store.commit('SET_SEARCH_DISABLED', disabled)
}

export function record(callback, fail) {
  rec.open(() => {
    rec.start()
    if (callback)
      callback()
  }, (err) => {
    if (fail)
      fail(err)
  })
}

// 识别启动、停止、清空操作
export function start(flag= false) {
  isReal = flag
  // store.commit('SET_SEARCH_DISABLED', true)
  // 清除显示
  clear()
  // 启动连接
  const ret = wsconnecter.wsStart()
  // 1 is ok, 0 is error
  if (ret === 1) {
    console.log('正在连接asr服务器,请等待...')
    disabled = true
    isRec = true
    return 1
  }
  else {
    return 0
  }
}

export function stop(err = false) {
  if (err) {
    wsconnecter.wsStop()
    return
  }
  disabled = true
  store.commit('SET_SEARCH_DISABLED', disabled)
  const chunk_size = [5, 10, 5]
  const request = {
    chunk_size,
    wav_name: 'h5',
    is_speaking: false,
    chunk_interval: 10,
    mode: '2pass',
  }
  if (sampleBuf.length > 0) {
    wsconnecter.wsSend(sampleBuf)
    sampleBuf = new Int16Array()
  }
  wsconnecter.wsSend(JSON.stringify(request))
  // 控件状态更新
  isRec = false
  console.log('发送完数据,请等候,正在识别...')
  // wait 3s for asr result
  const timer = setTimeout(() => {
    clearTimeout(timer)
    disabled = false
    store.commit('SET_SEARCH_DISABLED', disabled)
    console.log('ws停止')
  }, 3000)

  rec.stop()
  wsconnecter.wsStop()

  // 不需要获取音频文件, 直接关闭
  rec.close()
}

function clear() {
  recText = ''
  offlineText = ''
  store.commit('SET_SEARCH_RECTEXT', recText)
}

function recProcess(buffer, powerLevel, bufferDuration, bufferSampleRate) {
  if (isRec === true) {
    const data_48k = buffer[buffer.length - 1]
    const array_48k = new Array(data_48k)
    // @ts-expect-error no-err
    const data_16k = Recorder.SampleData(array_48k, bufferSampleRate, 16000).data
    sampleBuf = Int16Array.from([...sampleBuf, ...data_16k])
    const chunk_size = 960 // for asr chunk_size [5, 10, 5]
    while (sampleBuf.length >= chunk_size) {
      const sendBuf = sampleBuf.slice(0, chunk_size)
      sampleBuf = sampleBuf.slice(chunk_size, sampleBuf.length)
      wsconnecter.wsSend(sendBuf)
    }
  }
}
