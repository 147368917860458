<template>
  <div class="md-saveDialog">
    <a-modal
      v-model="visible"
      title="保存文件"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <!-- <a-spin :spinning="loading"> -->
      <a-form :form="form">
        <a-form-item label="文件名称" name="fileName" rules="required">
          <a-input
            v-decorator="[
              'fileName',
              {
                rules: [
                  { required: true, message: '必填' },
                  { max: 15, message: '超过最大字数限制' },
                  { validator: validateNotEmpty, trigger: 'blur' },
                ],
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item
          label="文件类型"
          name="fileType"
          rules="required"
          :rules="[{ required: true, message: '必填' }]"
        >
          <a-radio-group
            v-decorator="[
              'fileType',
              { rules: [{ required: true, message: '必填' }] },
            ]"
          >
            <a-radio value="MD">MD(.md)</a-radio>
            <a-radio value="DOCX">Word(.docx)</a-radio>
            <a-radio value="PDF">PDF(.pdf)</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      visible: false,
      // loading:false,
      form: {},
    };
  },
  props: ["visibleSaveDialogFlag"], // 接收父组件传递的formData属性
  watch:{
    visibleSaveDialogFlag: {
      handler(newVal) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },
  mounted() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    validateNotEmpty(rule, value, callback) {
      if (!value || value.trim() === "") {
        callback(new Error("文件名称不能为空"));
      } else {
        callback();
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.$emit("saveToDoc", {
            ...values,
          });
        }
      });
    },
    handleCancel() {
      this.$emit("closevisibleSaveDialog");
    },
  },
};
</script>

<style>
  .md-saveDialog {
    user-select: none !important;
  }

</style>
