/* eslint-disable no-console */
export function WebSocketConnectMethod(config) { // 定义socket连接方法类
  let speechSokt
  const msgHandle = config.msgHandle
  const stateHandle = config.stateHandle

  this.wsStart = function () {
    const Uri = process.env.VUE_APP_WS_ADDRESS
    if (Uri?.match(/wss:\S*|ws:\S*/))
      console.log(`ws地址:${Uri}`)
    else
      return 0

    if ('WebSocket' in window) {
      speechSokt = new WebSocket(Uri) // 定义socket连接对象
      speechSokt.onopen = function () {
        onOpen()
      } // 定义响应函数
      speechSokt.onclose = function () {
        onClose()
      }
      speechSokt.onmessage = function (e) {
        onMessage(e)
      }
      speechSokt.onerror = function () {
        onError()
      }
      return 1
    }
    else {
      return 0
    }
  }

  // 定义停止与发送函数
  this.wsStop = function () {
    if (speechSokt !== undefined) {
      console.log('stop ws!')
      speechSokt.close()
    }
  }

  this.wsSend = function (oneData) {
    if (speechSokt === undefined)
      return
    if (speechSokt.readyState === 1) { // 0:CONNECTING, 1:OPEN, 2:CLOSING, 3:CLOSED
      speechSokt.send(oneData)
    }
  }

  // SOCEKT连接中的消息与状态响应
  function onOpen() {
    // 发送json
    const chunk_size = [5, 10, 5]
    const request = {
      chunk_size,
      wav_name: 'h5',
      is_speaking: true,
      chunk_interval: 10,
      mode: '2pass',
      hotwords: '',
    }
    speechSokt?.send(JSON.stringify(request))
    stateHandle(0)
  }

  function onClose() {
    stateHandle(1)
  }

  function onMessage(e) {
    msgHandle(e)
  }

  function onError() {
    stateHandle(2)
  }
}
